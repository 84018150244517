import _ from 'lodash';

import i18n from '../i18n';
import { SORT } from '../hooks/CatalogSettings';
import Currency from '../modules/Currency';

export const isNumeric = (str) => str && /^\d+$/.test(str);

export const escapeRegex = (str) => (str ? str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : '');

export const getCompareFunction = (sort) => {
  if (!sort) return () => 0;
  const s = SORT[sort - 1];
  const p = s.property;
  if (s.type === 'numeric') {
    return (a, b) => {
      if (a.auction?.number && s.property === 'number') {
        const auctionSort = (a.auction.date.localeCompare(b.auction.date) * s.order * -1);
        if (auctionSort !== 0) return auctionSort;
      }
      return ((a[p] - b[p]) * s.order);
    };
  }
  return (a, b) => (a[p].localeCompare(b[p]) * s.order);
}

export const getTranslation = (obj, desc) => {
  const lang = i18n.getLang();
  if (obj === undefined) return '';
  if (lang === undefined) return '';
  if (obj instanceof Array) {
    const translation = obj.filter((c) => c.lang === lang);
    if (translation.length > 0) {
      return translation[0][desc];
    }
  }
  if (lang === 'de' || !obj[lang]) {
    return _.get(obj, desc, '');
  }
  return _.get(obj[lang], desc, '');
}

export const makeFilename = (name, extension) => {
  const ext = extension || 'pdf';
  const date = new Date();
  const filename = i18n.t('general.filename', { date, name });
  return `${filename}.${ext}`;
}

export const replaceLinebreaks = (s) => s && s.replaceAll('\n', '<br />');

export const capitalize = (s) => s?.length > 0 ? `${s[0].toUpperCase()}${s.slice(1)}` : '';

export const stripHTML = (s) => {
  const element = document.createElement('div');
  element.innerHTML = s;
  return element.textContent.trim();
}

export const getDisplayValue = (value, currency) => {
  if (value === '') return '';
  return i18n.currency(value, { currency });
}

export const getEditingValue = (value, currency) => {
  if (value === 0 || value === '') return value;
  return Currency.toFloat(value, currency);
}