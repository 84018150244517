import PropTypes from 'prop-types';
import React from 'react';
import { AuthProvider } from 'react-oidc-context';

const authority = process.env.REACT_APP_OIDC_AUTHORITY;

// https://github.com/IdentityModel/oidc-client-js/wiki

const OAuthProvider = ({ children = null }) => {

  // TODO: fix centered popup position
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  const width = 500;
  const height = 500;
  const popupFeatures = `location=no,toolbar=no,width=${width},height=${height},left=${(vw - width) / 2},top=${(vh - height) / 2}`;

  return (
    <AuthProvider
      authority={authority}
      automaticSilentRenew={true}
      client_id="frontend"
      loadUserInfo={true}
      onSigninCallback={() => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }}
      redirect_uri={`${location.origin}/portal`}
      popupWindowFeatures={popupFeatures}
      popupWindowTarget="portalLogin"
    >
      {children}
    </AuthProvider>
  );
};

OAuthProvider.propTypes = {
  children: PropTypes.node,
};

export default OAuthProvider;
