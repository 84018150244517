import GavelIcon from '@mui/icons-material/Gavel';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { sendLogout } from '../modules/portal';

const AccountMenu = ({ anchorEl = null, setAnchorEl }) => {
  const { removeUser, user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handlePortalClick = () => navigate('/portal');
  const handleProfileClick = () => navigate('/profile');
  const handleSettingsClick = () => navigate('/settings');

  const logout = React.useCallback(async () => {
    await sendLogout(user);
    removeUser();
  }, [removeUser, user]);

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 19,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handlePortalClick}>
        <ListItemIcon>
          <GavelIcon />
        </ListItemIcon>
        {t('account.links.portal')}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleProfileClick}>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        {t('account.links.profile')}
      </MenuItem>
      <MenuItem onClick={handleSettingsClick}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        {t('account.links.settings')}
      </MenuItem>
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        {t('account.links.logout')}
      </MenuItem>
    </Menu>
  )
};

AccountMenu.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
};

export default AccountMenu;