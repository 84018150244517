import { useQuery } from '@apollo/client';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import PropTypes from 'prop-types';

import { useCache } from './CacheProvider';
import { userIsAuctioneer } from '../modules/portal';
import { AUCTION_QUERY, AUCTION_SUBSCRIPTION, LOTS_SUBSCRIPTION } from '../schema/portal';

const PortalContext = React.createContext(null);

const usePortal = () => {
  const context = React.useContext(PortalContext);

  return context;
};

// eslint-disable-next-line react/display-name
const withPortal = (Component) => (props) => {
  const portal = usePortal();

  return <Component {...props} portal={portal} />;
};

const PortalProvider = ({ children = null }) => {
  const { user } = useAuth();
  const cache = useCache();
  const [adminView, setAdminView] = React.useState(userIsAuctioneer(user));
  const [auctions, setAuctions] = React.useState([]);
  const [lots, setLots] = React.useState([]);
  const [portalLoading, setPortalLoading] = React.useState(true);
  const [lotsLoading, setLotsLoading] = React.useState(true);

  const auction = auctions?.[0] || {};
  const auctionNumber = auction.number || '';
  const userId = user?.profile?.sub || null;

  const { data, error, loading, subscribeToMore } = useQuery(AUCTION_QUERY, { variables: { auctionNumber } });

  React.useEffect(() => {
    const controller = new AbortController();
    cache.fetchData(setAuctions, setPortalLoading, 'portal', null, controller);
    return () => controller.abort();
  }, []);

  React.useEffect(() => {
    if (auctionNumber && auctionNumber !== '') {
      const controller = new AbortController();
      cache.fetchData(setLots, setLotsLoading, `lots/${auctionNumber}`, null, controller);
      return () => controller.abort();
    }
  }, [auctionNumber]);

  React.useEffect(() => {
    if (!auctionNumber) return;
    subscribeToMore({
      document: AUCTION_SUBSCRIPTION,
      variables: { number: auctionNumber },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const auction = subscriptionData.data.auctionChanged;
        return {
          ...prev,
          auction,
        };
      },
    });
    subscribeToMore({
      document: LOTS_SUBSCRIPTION,
      variables: { auctionNumber },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const lot = subscriptionData.data.lotChanged;
        if (prev.lots.find(l => l._id === lot._id)) {
          return {
            ...prev,
            lots: prev.lots.map(l => l._id === lot._id ? lot : l),
          };
        }
        return {
          ...prev,
          lots: [...prev.lots, lot],
        };
      },
    });
  }, [auctionNumber, subscribeToMore]);

  const combinedAuction = React.useMemo(() => ({
    ...auction,
    portalInfo: data && data.auction,
  }), [auction, data && data.auction]);

  const addAdvanceBids = (pLot, bids) => {
    if (!pLot) return pLot;
    return {
      ...pLot,
      advanceBids: bids.filter(b => b.lotId === pLot._id),
    };
  };

  const portalList = React.useMemo(() => {
    if (!(data && data.advanceBids && data.lots && lots)) return [];
    return lots
      .map(l => ({
        ...l,
        portalInfo: addAdvanceBids(data.lots.find(pl => pl.reference === l._id), data.advanceBids),
        ref: React.createRef(),
      }))
      .filter(l => !!l.portalInfo);
  }, [data && data.advanceBids, data && data.lots, lots]);

  const activeLot = React.useMemo(() => {
    const portalActiveLotId = data && data.auction && data.auction.currentLotId;
    return portalList.find(l => l.portalInfo._id === portalActiveLotId);
  }, [data, portalList]);

  React.useEffect(() => {
    if (userIsAuctioneer(user)) {
      setAdminView(true);
    }
  }, [user?.profile?.sub]);

  const anyLoading = portalLoading || lotsLoading || loading;

  return (
    <PortalContext.Provider value={{
      adminView,
      auction: combinedAuction,
      activeLot,
      error,
      loading: anyLoading,
      lots: portalList,
      setAdminView,
      userId,
    }}>
      {children}
    </PortalContext.Provider>
  );
};

PortalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { PortalProvider, usePortal, withPortal };
