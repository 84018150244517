import { gql } from '@apollo/client';

export const AUCTION_QUERY = gql`
  query AuctionQuery($auctionNumber: String!) {
    auction(number: $auctionNumber) {
      currentLotId
      increments {
        from
        value
      }
      name
      number
      status
    }
    advanceBids(auctionNumber: $auctionNumber) {
      _id
      lotId
      amount
      phone
    }
    lots(auctionNumber: $auctionNumber) {
      _id
      currentBid
      currentHolder
      increment
      limit
      name
      number
      order
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
      }
      reference
      status
    }
  }
`;

export const AUCTION_SUBSCRIPTION = gql`
  subscription AuctionSubscription($number: String!) {
    auctionChanged(number: $number) {
      _id
      currentLotId
      increments {
        from
        value
      }
      name
      number
      status
    }
  }
`;

export const LOTS_SUBSCRIPTION = gql`
  subscription LotsSubscription($auctionNumber: String!) {
    lotChanged(auctionNumber: $auctionNumber) {
      _id
      auctionId
      currentBid
      currentHolder
      increment
      limit
      name
      number
      order
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
      }
      reference
      status
    }
  }
`;

export const MESSAGES_SUBSCRIPTION = gql`
  subscription MessagesSubscription($auctionNumber: String!) {
    messageReceived(auctionNumber: $auctionNumber) {
      _id
      type
      text
    }
  }
`;

export const OPEN_AUCTION = gql`
  mutation OpenAuction($number: String!) {
    openAuction(number: $number) {
      _id
      status
    }
  }
`;

export const CLOSE_AUCTION = gql`
  mutation CloseAuction($number: String!) {
    closeAuction(number: $number) {
      _id
      status
    }
  }
`;

export const PAUSE_AUCTION = gql`
  mutation PauseAuction($number: String!) {
    pauseAuction(number: $number) {
      _id
      status
    }
  }
`;

export const RESUME_AUCTION = gql`
  mutation ResumeAuction($number: String!) {
    resumeAuction(number: $number) {
      _id
      status
    }
  }
`;

export const POST_MESSAGE = gql`
  mutation PostMessage($auctionNumber: String!, $message: MessageInput!) {
    postMessage(auctionNumber: $auctionNumber, message: $message) {
      _id
      type
      text
    }
  }
`;

export const NEXT_LOT = gql`
  mutation NextLot($number: String!) {
    nextLot(number: $number) {
      _id
    }
  }
`;

export const PREVIOUS_LOT = gql`
  mutation PreviousLot($number: String!) {
    previousLot(number: $number) {
      _id
    }
  }
`;

export const OPEN_LOT = gql`
  mutation OpenLot($lotId: ID!) {
    openLot(lotId: $lotId) {
      _id
      increment
      status
    }
  }
`;

export const HAMMER_LOT = gql`
  mutation HammerLot($lotId: ID!) {
    hammerLot(lotId: $lotId) {
      _id
      status
    }
  }
`;

export const CLOSE_LOT = gql`
  mutation CloseLot($lotId: ID!) {
    closeLot(lotId: $lotId) {
      _id
      status
    }
  }
`;

export const RESET_LOT = gql`
  mutation ResetLot($lotId: ID!) {
    resetLot(lotId: $lotId) {
      _id
      currentBid
      currentHolder
      increment
      status
    }
  }
`;

export const SET_INCREMENT = gql`
  mutation SetIncrement($lotId: ID!, $increment: Int!) {
    setIncrement(lotId: $lotId, increment: $increment) {
      _id
      increment
    }
  }
`;

export const PLACE_BID = gql`
  mutation PlaceBid($bid: BidInput) {
    placeBid(bid: $bid) {
      _id
      currentBid
      currentHolder
      increment
    }
  }
`;

export const REVERT_BID = gql`
  mutation RevertBid($revert: RevertInput) {
    revertBid(revert: $revert) {
      _id
      currentBid
      currentHolder
      increment
    }
  }
`;
