import { useMutation } from '@apollo/client';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, IconButton, styled, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

import { usePortal } from '../../hooks/PortalProvider';
import { auctionIsLive, auctionIsPaused } from '../../modules/portal';

import {
  // OPEN_AUCTION,
  PAUSE_AUCTION,
  RESUME_AUCTION,
} from '../../schema/portal';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '2.5rem',
  left: '1rem',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.light.main,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const AdminTime = () => {
  const [time, setTime] = React.useState(new Date().toLocaleTimeString());
  const { auction } = usePortal();

  // const [openAuction] = useMutation(OPEN_AUCTION);
  const [pauseAuction] = useMutation(PAUSE_AUCTION);
  const [resumeAuction] = useMutation(RESUME_AUCTION);

  // const handleOpenAuction = () => {
  //   openAuction({ variables: { number: auction.number } });
  // }

  const toggleAuctionPause = () => {
    if (auctionIsPaused(auction)) {
      resumeAuction({ variables: { number: auction.number } });
    } else if (auctionIsLive(auction)) {
      pauseAuction({ variables: { number: auction.number } });
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
  }, [time]);

  return (
    <StyledBox>
      <Typography variant="body1">
        {moment(new Date()).format('L')}
      </Typography>
      <Box
       sx={{
        display: 'flex',
        alignItems: 'center',
       }}
      >
        <Typography variant="h4">
          {time}
        </Typography>
        {auctionIsPaused(auction) || auctionIsLive(auction) ? (
          <IconButton
            color="inherit"
            onClick={toggleAuctionPause}
          >
            {auctionIsPaused(auction)
              ? <PlayCircleOutlineIcon sx={{ fontSize: '2rem' }} />
              : <PauseCircleOutlineIcon sx={{ fontSize: '2rem' }} />}
          </IconButton>
        ) : null}
      </Box>
    </StyledBox>
  );
};

export default AdminTime;