import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './assets/theme';
import ApolloProvider from './hooks/ApolloProvider';
import { AppProvider } from './hooks/AppProvider';
import { CacheProvider } from './hooks/CacheProvider';
import { CookieProvider } from './hooks/CookieProvider';
import { NavProvider } from './hooks/NavProvider';
import OAuthProvider from './hooks/OAuthProvider';
import { PortalProvider } from './hooks/PortalProvider';
import { UserProvider } from './hooks/UserProvider';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <OAuthProvider>
      <ApolloProvider>
        <CacheProvider>
          <PortalProvider>
            <CookieProvider>
              <HelmetProvider>
                <AppProvider>
                  <UserProvider>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Router>
                        <NavProvider>
                          <App />
                        </NavProvider>
                      </Router>
                    </ThemeProvider>
                  </UserProvider>
                </AppProvider>
              </HelmetProvider>
            </CookieProvider>
          </PortalProvider>
        </CacheProvider>
      </ApolloProvider>
    </OAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
